import React from 'react';

export default (props) => {
  const { title = 'Internet Archive logo' } = props;
  return (
    <figure {...props}>
      <svg
        height="20"
        width="22"
        viewBox="0 0 32 42"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="ia-logo-svg"
        role="img"
      >
        <title id="ia-logo-svg">{ title }</title>
        <g fill="#fff" fillRule="evenodd">
          <path d="m0 4.4796473 18.707282-4.4796473 18.4263458 4.23309464-.7386684 1.30442147h-35.29099323" />
          <path d="m.93617021 6.55319149h35.39634429v3.80921841h-35.39634429z" />
          <path d="m.93617021 38.3829787h35.39634429v2.539209h-35.39634429z" />
          <path d="m.00040364 41.1914894h37.94212666v1.9048116h-37.9425303z" />
          <path d="m1.34062144 11.4275601.43997189-.1935175h2.35566603l.42180792.2117357.21110578 3.3857469.21110578 5.5014846v5.2909635l-.21110578 6.5953849-.03511702 4.443211-.59779668.1769187h-2.10984687l-.63291369-.1769187-.24581916-4.443211-.21150943-6.5601631v-5.2905586l.21150943-5.5371113z" />
          <path d="m32.2342385 11.4275601.4395682-.1935175h2.355666l.4222116.2117357.2107021 3.3857469.2107022 5.5014846v5.2909635l-.2107022 6.5953849-.0347133 4.443211-.5982004.1769187h-2.1094432l-.6329137-.1769187-.2466264-4.443211-.2107022-6.5601631v-5.2905586l.2107022-5.5371113z" />
          <path d="m11.6384938 11.4275601.4391646-.1935175h2.3560697l.4218079.2117357.2111058 3.3857469.2111057 5.5014846v5.2909635l-.2111057 6.5953849-.0347134 4.443211-.5982003.1769187h-2.1098469l-.6333174-.1769187-.2458191-4.443211-.2107021-6.5601631v-5.2905586l.2107021-5.5371113z" />
          <path d="m22.8725363 11.4275601.4395683-.1935175h2.355666l.4218079.2117357.2111058 3.3857469.2111058 5.5014846v5.2909635l-.2111058 6.5953849-.0347134 4.443211-.5982003.1769187h-2.1098469l-.6329137-.1769187-.2462228-4.443211-.2107021-6.5601631v-5.2905586l.2107021-5.5371113z" />
        </g>
      </svg>
    </figure>
  );
};
