import React from 'react';

export default (props) => {
  const { title = 'Spotify logo' } = props;
  return (
    <figure {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="20"
        width="20"
        viewBox="0 0 166 166"
        aria-labelledby="spotify-logo"
        role="img"
      >
        <title id="spotify-logo">{ title }</title>
        <path fill="#1ED760" fillRule="evenodd" d="M133.28,74.24 C106.286,58.209 61.76,56.735 35.991,64.556 C31.852,65.812 27.476,63.476 26.222,59.338 C24.968,55.197 27.302,50.824 31.443,49.566 C61.024,40.587 110.199,42.321 141.275,60.768 C144.997,62.978 146.218,67.785 144.012,71.501 C141.804,75.223 136.993,76.45 133.28,74.24 Z M132.396,97.984 C130.502,101.057 126.484,102.021 123.415,100.134 C100.91,86.301 66.593,82.293 39.968,90.375 C36.515,91.418 32.869,89.472 31.82,86.025 C30.78,82.573 32.727,78.933 36.174,77.883 C66.589,68.653 104.4,73.123 130.248,89.008 C133.317,90.898 134.283,94.918 132.396,97.984 Z M122.149,120.787 C120.644,123.255 117.431,124.028 114.972,122.524 C95.307,110.505 70.555,107.791 41.405,114.449 C38.596,115.093 35.796,113.333 35.155,110.524 C34.512,107.716 36.266,104.916 39.081,104.275 C70.981,96.983 98.344,100.121 120.417,113.609 C122.878,115.112 123.653,118.327 122.149,120.787 Z M83.743,0 C37.494,0 -1.42108547e-12,37.493 -1.42108547e-12,83.742 C-1.42108547e-12,129.996 37.494,167.486 83.743,167.486 C129.994,167.486 167.486,129.996 167.486,83.742 C167.486,37.493 129.994,0 83.743,0 Z" />
      </svg>
    </figure>
  );
};
