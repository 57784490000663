import { property, customElement, LitElement, html, css } from '../lit-element.js';

var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let TranscriptEntry = class TranscriptEntry extends LitElement {
  constructor() {
    super(...arguments);
    this.entry = undefined;
    this.isActive = false;
    this.isSelected = false;
    this.isClickable = false;
    this.isSearchResult = false;
    this.isMusicEntry = false;
  }

  render() {
    return html`
      ${this.entry ? this.entry.displayText : ''}
    `;
  }

};

__decorate([property({
  type: Object
})], TranscriptEntry.prototype, "entry", void 0);

__decorate([property({
  type: Boolean,
  reflect: true
})], TranscriptEntry.prototype, "isActive", void 0);

__decorate([property({
  type: Boolean,
  reflect: true
})], TranscriptEntry.prototype, "isSelected", void 0);

__decorate([property({
  type: Boolean,
  reflect: true
})], TranscriptEntry.prototype, "isClickable", void 0);

__decorate([property({
  type: Boolean,
  reflect: true
})], TranscriptEntry.prototype, "isSearchResult", void 0);

__decorate([property({
  type: Boolean,
  reflect: true
})], TranscriptEntry.prototype, "isMusicEntry", void 0);

TranscriptEntry = __decorate([customElement('transcript-entry')], TranscriptEntry);

var __decorate$1 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let DurationFormatter = class DurationFormatter extends LitElement {
  constructor() {
    super(...arguments);
    this.seconds = 0;
  }

  render() {
    return html`
      ${this.durationString}
    `;
  }

  get durationString() {
    if (typeof this.seconds !== 'number') {
      return '';
    }

    const hours = Math.floor(this.seconds / 3600);
    const minutes = Math.floor(this.seconds / 60) % 60;
    const seconds = Math.floor(this.seconds % 60);
    return [hours, minutes, seconds].map(v => v < 10 ? `0${v}` : v).filter((v, i) => v !== '00' || i > 0).join(':');
  }

};

__decorate$1([property({
  type: Number
})], DurationFormatter.prototype, "seconds", void 0);

DurationFormatter = __decorate$1([customElement('duration-formatter')], DurationFormatter);

class TranscriptEntryConfig {
  constructor(id, start, end, text, isMusic, searchMatchIndex) {
    this.id = id;
    this.start = start;
    this.end = end;
    this.text = text;
    this.isMusic = isMusic;
    this.searchMatchIndex = searchMatchIndex;
  }

  get displayText() {
    if (this.isMusic) {
      return `[Transcript unavailable]`;
    }

    return this.text;
  }

}

class TranscriptConfig {
  constructor(entries) {
    this.entries = [];
    this.entries = entries;
  }

  get searchResults() {
    return this.entries.filter(entry => entry.searchMatchIndex !== undefined);
  }

}

var __decorate$2 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let TranscriptView = class TranscriptView extends LitElement {
  constructor() {
    super(...arguments);
    this.config = undefined;
    this.currentTime = 0;
    this.topContextHeight = 50;
    this.bottomContextHeight = 50;
    this.autoScroll = true;
    this.selectedSearchResultIndex = 0;
    this.showContextZones = false;
    this.timeScrollTop = 0;
    this.scrollTimerDelay = 15000;
    this.scrollResumeTimerId = -1;
  }

  render() {
    return html`
      <div class="container">
        ${this.showContextZones ? this.contextZoneDevTemplates : ''}

        <div class="scroll-container" id="scroll-container" @wheel=${this.didScroll}>
          <div class="col time">
            ${this.timeDisplayTemplate}
          </div>

          <div class="col">
            ${this.autoScrollButtonTemplate}
            ${this.transcriptEntries.map(entry => this.transcriptEntryTemplate(entry))}
          </div>
        </div>
      </div>
    `;
  }

  scrollToSelectedSearchResult() {
    const {
      selectedSearchResult
    } = this;

    if (!selectedSearchResult) {
      return;
    }

    this.autoScroll = false;
    this.scrollToElement(selectedSearchResult);
  }

  get autoScrollButtonTemplate() {
    return html`
      <button
        @click=${this.enableAutoScroll}
        class="auto-scroll-button ${this.autoScroll ? 'hidden' : ''}"
      >
        Scroll text with audio
      </button>
    `;
  }

  get timeDisplayTemplate() {
    return html`
      <div class="time-display" style="top: ${this.timeScrollTop}px">
        <duration-formatter .seconds=${this.currentTime}> </duration-formatter>
      </div>
    `;
  }

  transcriptEntryTemplate(entry) {
    const currentEntryId = this.currentEntry ? this.currentEntry.id : -1;
    const active = entry.id === currentEntryId;
    const selected = entry.searchMatchIndex === this.selectedSearchResultIndex;
    const isSearchResult = entry.searchMatchIndex !== undefined;
    const isMusicEntry = entry.isMusic;
    return html`
      <transcript-entry
        .entry=${entry}
        ?isSelected=${selected}
        ?isActive=${active}
        ?isSearchResult=${isSearchResult}
        ?isMusicEntry=${isMusicEntry}
        isClickable=true
        data-search-result-index=${entry.searchMatchIndex}
        data-identifier=${entry.id}
        @click=${this.transcriptEntrySelected}
      >
      </transcript-entry>
    `;
  }

  get contextZoneDevTemplates() {
    return html`
      ${this.topContextZoneDevTemplate} ${this.bottomContextZoneDevTemplate}
    `;
  }

  get topContextZoneDevTemplate() {
    return html`
      <div class="top context-overlay" style="height: ${this.topContextHeight}px"></div>
    `;
  }

  get bottomContextZoneDevTemplate() {
    return html`
      <div class="bottom context-overlay" style="height: ${this.bottomContextHeight}px"></div>
    `;
  }

  get transcriptEntries() {
    return this.config ? this.config.entries : [];
  }

  static get styles() {
    const transcriptHeightCss = css`var(--transcriptHeight, 200px)`;
    const timeColorCss = css`var(--timeColor, white)`;
    const timeColumnWidthCss = css`var(--timeColumnWidth, 3rem)`;
    const timeDisplayCss = css`var(--timeDisplay, block)`;
    const autoScrollButtonFontColorCss = css`var(--autoScrollButtonFontColor, black)`;
    const autoScrollButtonBackgroundColorCss = css`var(--autoScrollButtonBackgroundColor, white)`;
    const autoScrollButtonWidthCss = css`var(--autoScrollButtonWidth, 8rem)`;
    const normalTextColor = css`var(--transcriptNormalTextColor, gray)`;
    const activeTextColor = css`var(--transcriptActiveTextColor, white)`;
    const hoverTextColor = css`var(--transcriptHoverTextColor, silver)`;
    const musicNormalTextColor = css`var(--transcriptMusicNormalTextColor, gray)`;
    const musicActiveTextColor = css`var(--transcriptMusicActiveTextColor, white)`;
    const musicHoverTextColor = css`var(--transcriptMusicActiveTextColor, silver)`;
    const searchResultInactiveBorderColor = css`var(--transcriptSearchResultInactiveBorderColor, gray)`;
    const searchResultActiveBorderColor = css`var(--transcriptSearchResultActiveBorderColor, green)`;
    return css`
      :host {
        color: ${normalTextColor}
      }

      .container {
        position: relative;
      }

      .auto-scroll-button.hidden {
        display: none;
      }

      .auto-scroll-button {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1rem;
        margin: auto;
        width: ${autoScrollButtonWidthCss};
        border-radius: 1rem;
        border: 0;
        display: inline-block;
        color: ${autoScrollButtonFontColorCss};
        background-color: ${autoScrollButtonBackgroundColorCss};
        z-index: 10;
      }

      .context-overlay {
        position: absolute;
        left: 0;
        width: 100%;
        height: 0;
        z-index: -1;
      }

      .context-overlay.top {
        top: 0;
        border-bottom: 1px solid green;
      }

      .context-overlay.bottom {
        bottom: 0;
        border-top: 1px solid green;
      }

      .time {
        display: ${timeDisplayCss};
        flex: 0 0 ${timeColumnWidthCss};
        color: ${timeColorCss};
        position: relative;
      }

      .time-display {
        position: absolute;
        top: 0;
        line-height: 1rem;
        transition: top 1s;
      }

      .scroll-container {
        display: flex;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        height: ${transcriptHeightCss};
      }

      .scroll-container::-webkit-scrollbar {
        display: none;
      }

      transcript-entry {
        cursor: pointer;
      }

      transcript-entry:hover {
        color: ${hoverTextColor};
      }

      transcript-entry[ismusicentry] {
        color: ${musicNormalTextColor};
        display: block;
        font-style: italic;
      }

      transcript-entry[ismusicentry]:hover {
        color: ${musicHoverTextColor};
      }

      transcript-entry[ismusicentry][isactive] {
        color: ${musicActiveTextColor};
      }

      transcript-entry[isactive] {
        color: ${activeTextColor};
      }

      transcript-entry[issearchresult] {
        display: inline-block; /* without this, the outline adds an extra space to the right of the text */
        padding: 0 5px;
        position: relative;
      }

      transcript-entry[issearchresult]:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 2px solid ${searchResultInactiveBorderColor};
        border-radius: 5px;
      }

      transcript-entry[issearchresult][isselected]:after {
        border: 2px solid ${searchResultActiveBorderColor};
      }

    `;
  }

  transcriptEntrySelected(e) {
    const entry = e.target.entry;

    if (!entry) {
      return;
    }

    const event = new CustomEvent('transcriptEntrySelected', {
      detail: {
        entry
      }
    });
    this.dispatchEvent(event);

    if (entry.searchMatchIndex !== undefined) {
      this.selectedSearchResultIndex = entry.searchMatchIndex;
    }

    this.autoScroll = false;
  }

  handleCurrentTimeChange() {
    const entries = this.transcriptEntries;

    if (entries.length === 0) {
      return;
    }

    const activeEntry = entries.find( // eslint-disable-next-line max-len
    entry => this.currentTime >= entry.start && this.currentTime <= entry.end);

    if (!activeEntry) {
      this.currentEntry = undefined;
      return;
    } // this method gets called for every time update, which happens several times per second, but
    // we only want to update the UI if the `currentEntry` has actually changed
    // (ie, their ids don't match)


    if (this.currentEntry && this.currentEntry.id === activeEntry.id) {
      return;
    }

    this.currentEntry = activeEntry;
  } // This finds the transcript entry that is closest to a given time.
  //
  // If we don't have a currentEntry to work with, ie. we're in-between transcript entries or we're before the
  // transcript starts or after it ends, we want to find the element closest to the time. This allows
  // us to scroll to the proper location and set the current time's position.
  //
  // This is a somewhat heavy method since it has to check all of the entries so it's faster
  // to rely on accessing the `currentEntry` element if you can, but this should work for any given time.


  entryIdentifierClosestToTime(time) {
    if (this.transcriptEntries.length === 0) {
      return null;
    }

    const firstEntry = this.transcriptEntries[0];
    var delta = Math.abs(time - firstEntry.start);
    var closestIdentifier = firstEntry.id;
    this.transcriptEntries.forEach(entry => {
      const entryDelta = Math.abs(time - entry.start); // if the entryDelta is less than the previous delta, we're moving closer to `time`;
      // once the delta starts increasing, we're moving away from it so we've just passed the closest

      if (entryDelta < delta) {
        delta = entryDelta;
        closestIdentifier = entry.id;
      } else {
        return;
      }
    });
    return closestIdentifier;
  }

  elementClosestToTime(time) {
    const closestIdentifier = this.entryIdentifierClosestToTime(time);

    if (!closestIdentifier) {
      return null;
    }

    return this.elementForIdentifier(closestIdentifier);
  }

  elementForIdentifier(identifier) {
    return this.shadowRoot && this.shadowRoot.querySelector(`transcript-entry[data-identifier="${identifier}"]`);
  }

  didScroll() {
    this.autoScroll = false;
    window.clearTimeout(this.scrollResumeTimerId);
    this.scrollResumeTimerId = window.setTimeout(() => {
      this.autoScroll = true;
    }, this.scrollTimerDelay);
  }

  enableAutoScroll() {
    this.autoScroll = true;
    this.scrollToClosestEntry();
  }

  updated(changedProperties) {
    if (changedProperties.has('currentTime')) {
      this.handleCurrentTimeChange();
    }

    if (changedProperties.has('selectedSearchResultIndex')) {
      this.scrollToSelectedSearchResult();
    }

    if (changedProperties.has('currentEntry')) {
      this.scrollToClosestEntry();
      this.updateTimePosition();
    }

    if (changedProperties.has('autoScroll')) {
      this.handleAutoScrollChange();
    }

    if (changedProperties.has('config')) {
      this.selectedSearchResultIndex = 0;
      this.scrollToSelectedSearchResult();
    }
  }

  get scrollView() {
    return this.shadowRoot && this.shadowRoot.getElementById('scroll-container');
  }

  get activeTranscriptEntry() {
    return this.shadowRoot && this.shadowRoot.querySelector('transcript-entry[isActive]');
  }

  get selectedSearchResult() {
    const selectedResult = this.shadowRoot && this.shadowRoot.querySelector(`transcript-entry[data-search-result-index="${this.selectedSearchResultIndex}"]`);
    return selectedResult;
  }

  get closestEntryToCurrentTime() {
    return this.activeTranscriptEntry || this.elementClosestToTime(this.currentTime);
  }

  handleAutoScrollChange() {
    const autoScrollChangedEvent = new CustomEvent('autoScrollChanged', {
      detail: {
        autoScroll: this.autoScroll
      }
    });
    this.dispatchEvent(autoScrollChangedEvent);
  }

  scrollToClosestEntry() {
    if (!this.autoScroll) {
      return;
    }

    const closestEntry = this.closestEntryToCurrentTime;

    if (!closestEntry) {
      return;
    }

    this.scrollToElement(closestEntry);
  }

  scrollToElement(element) {
    const {
      scrollView
    } = this;

    if (!scrollView) {
      return;
    }

    const scrollContainerRect = scrollView.getBoundingClientRect();
    const activeEntryRect = element.getBoundingClientRect();
    const scrollContainerHeight = scrollContainerRect.height;
    const {
      topContextHeight
    } = this;
    const {
      bottomContextHeight
    } = this;
    const focusBottom = scrollContainerHeight - bottomContextHeight; // if the active entry is above the top context area or below the bottom of the focus area,
    // scroll it to the top of the focus area

    if (activeEntryRect.bottom > scrollContainerRect.top + focusBottom || activeEntryRect.top < scrollContainerRect.top) {
      // eslint-disable-next-line max-len
      const newTargetScrollPos = activeEntryRect.top - scrollContainerRect.top + scrollView.scrollTop - topContextHeight;
      this.scrollToOffsetWithDuration(newTargetScrollPos, 1);
    }
  }

  updateTimePosition() {
    const scrollToEntry = this.closestEntryToCurrentTime;

    if (!scrollToEntry) {
      return;
    }

    const parentNode = scrollToEntry.parentNode;
    const parentOffset = parentNode.getBoundingClientRect();
    const offset = scrollToEntry.getBoundingClientRect().top - parentOffset.top;
    this.timeScrollTop = offset;
  }

  scrollToOffsetWithDuration(offset, duration, onDone) {
    const {
      scrollView
    } = this;

    if (!scrollView) {
      return;
    }

    const start = scrollView.scrollTop;
    const change = offset - start;
    const startTime = performance.now();
    let now;
    let elapsed;
    let percentComplete;

    function easeInOutQuad(time) {
      return time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time;
    }

    function animateScroll() {
      if (!scrollView) {
        return;
      }

      now = performance.now();
      elapsed = (now - startTime) / 1000;
      percentComplete = elapsed / duration;
      scrollView.scrollTop = start + change * easeInOutQuad(percentComplete);

      if (percentComplete < 1) {
        window.requestAnimationFrame(animateScroll);
      } else if (onDone) {
        onDone();
      }
    }

    animateScroll();
  }

};

__decorate$2([property({
  type: TranscriptConfig
})], TranscriptView.prototype, "config", void 0);

__decorate$2([property({
  type: Number
})], TranscriptView.prototype, "currentTime", void 0);

__decorate$2([property({
  type: Number
})], TranscriptView.prototype, "topContextHeight", void 0);

__decorate$2([property({
  type: Number
})], TranscriptView.prototype, "bottomContextHeight", void 0);

__decorate$2([property({
  type: Boolean
})], TranscriptView.prototype, "autoScroll", void 0);

__decorate$2([property({
  type: Number
})], TranscriptView.prototype, "selectedSearchResultIndex", void 0);

__decorate$2([property({
  type: Boolean
})], TranscriptView.prototype, "showContextZones", void 0);

__decorate$2([property({
  type: Number
})], TranscriptView.prototype, "timeScrollTop", void 0);

__decorate$2([property({
  type: TranscriptEntryConfig
})], TranscriptView.prototype, "currentEntry", void 0);

TranscriptView = __decorate$2([customElement('transcript-view')], TranscriptView);

class RadioPlayerConfig {
  constructor(title, date, logoUrl, waveformUrl, audioSources, quickSearches = []) {
    this.quickSearches = [];
    this.title = title;
    this.date = date;
    this.logoUrl = logoUrl;
    this.waveformUrl = waveformUrl;
    this.audioSources = audioSources;
    this.quickSearches = quickSearches;
  }

}

var __decorate$3 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let WaveformProgress = class WaveformProgress extends LitElement {
  constructor() {
    super(...arguments);
    this.percentComplete = 0;
    this.waveformUrl = '';
    this.interactive = false;
    this.zonesOfSilence = []; // This is our internal, canonical source for the `percentComplete`.
    // The public `percentComplete` will be getting modified by outside modifiers
    // like the audio player, but since the user can scrub through the waveform,
    // we need to be able to control when that value gets updated

    this._percentComplete = 0;
    this._userIsInteracting = false;
  }

  render() {
    return html`
      <div class="container">
        <div id="fill" style="width: ${this._percentComplete}%"></div>
        <img class="waveform-image" src="${this.waveformUrl}">
        ${this.zonesOfSilenceTemplate}
        ${this.interactive ? this.interactionCoverTemplate : ''}
      </div>
      `;
  }

  get zonesOfSilenceTemplate() {
    return html`
      ${this.zonesOfSilence.map(zone => {
      return html`
          <div
            class="zone-of-silence"
            style="left: ${zone.startPercent}%; width: ${zone.endPercent - zone.startPercent}%"></div>
        `;
    })}
    `;
  }

  get interactionCoverTemplate() {
    return html`
      <div
        id="dragcover"

        @mousedown=${this.dragstart}
        @mouseup=${this.dragend}
        @mouseleave=${this.dragend}
        @mousemove=${this.drag}

        @touchstart=${this.dragstart}
        @touchend=${this.dragend}
        @touchmove=${this.drag}>
      </div>
    `;
  }

  updated(changedProperties) {
    if (!changedProperties.has('percentComplete') || this._userIsInteracting) {
      return;
    }

    this._percentComplete = this.percentComplete;
  }

  drag(e) {
    /* istanbul ignore if */
    if (!this._userIsInteracting) {
      return;
    }

    this.updatePercentComplete(e);
  }

  dragstart(e) {
    this._userIsInteracting = true;
    this.updatePercentComplete(e);
  }

  dragend(e) {
    this._userIsInteracting = false;
  }

  updatePercentComplete(e) {
    this._percentComplete = this.offsetXToPercent(e.offsetX);
    this.dispatchValueChangeEvent();
  }

  dispatchValueChangeEvent() {
    const event = new CustomEvent('valuechange', {
      detail: {
        value: this._percentComplete
      }
    });
    this.dispatchEvent(event);
  }

  get dragcover() {
    return this.shadowRoot && this.shadowRoot.getElementById('dragcover');
  }

  offsetXToPercent(offsetX) {
    /* istanbul ignore if */
    if (this.dragcover === null) {
      return 0;
    }

    const width = this.dragcover.clientWidth;
    const percentComplete = offsetX / width * 100;
    return percentComplete;
  }

  static get styles() {
    const fillColorCss = css`var(--fillColor, #3272b6)`;
    const zoneOfSilenceColorCss = css`var(--zoneOfSilenceColor, #f6e652)`;
    return css`
      :host {
        display: inline-block;
      }

      #dragcover {
        width: 100%;
        height: 100%;
        position: absolute;
      }

      .container {
        display: block;
        position: relative;
        background-color: white;
        height: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }

      .waveform-image {
        width: 100%;
        height: 100%;
        position: absolute;
      }

      .zone-of-silence {
        position: absolute;
        top: 0;
        bottom: 0;
        background: linear-gradient(#000, #000 47%, ${zoneOfSilenceColorCss} 50%, #000 53%, #000 100%);
      }

      #fill {
        position: absolute;
        height: 100%;
        background-color: ${fillColorCss};
      }
    `;
  }

};

__decorate$3([property({
  type: Number
})], WaveformProgress.prototype, "percentComplete", void 0);

__decorate$3([property({
  type: String
})], WaveformProgress.prototype, "waveformUrl", void 0);

__decorate$3([property({
  type: Boolean
})], WaveformProgress.prototype, "interactive", void 0);

__decorate$3([property({
  type: Array
})], WaveformProgress.prototype, "zonesOfSilence", void 0);

__decorate$3([property({
  type: Number
})], WaveformProgress.prototype, "_percentComplete", void 0);

WaveformProgress = __decorate$3([customElement('waveform-progress')], WaveformProgress);

class ZoneOfSilence {
  constructor(startPercent, endPercent) {
    this.startPercent = 0;
    this.endPercent = 0;
    this.startPercent = startPercent;
    this.endPercent = endPercent;
  }

}

var PlaybackMode;

(function (PlaybackMode) {
  PlaybackMode[PlaybackMode["playing"] = 0] = "playing";
  PlaybackMode[PlaybackMode["paused"] = 1] = "paused";
})(PlaybackMode || (PlaybackMode = {}));

const image = html`
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
  <g fill="none" fill-rule="evenodd" transform="translate(1)">
    <polygon fill="#FFF" points="7 3 16 17 -2 17" transform="rotate(90 7 10)"/>
    <line x1="15" x2="15" y1="20" stroke="#FFF" stroke-width="2"/>
  </g>
</svg>
`;

const image$1 = html`
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
  <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
    <polygon fill="#FFF" points="9 2 18 16 0 16" transform="rotate(-90 9 9)"/>
    <line x1=".5" x2=".5" y1="18" stroke="#FFF" stroke-width="2"/>
  </g>
</svg>
`;

const image$2 = html`
<svg width="20" height="20" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>icon/replay</title><desc>Created with Sketch.</desc><g id="icon/replay" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Replay-button"><polyline id="Path" stroke="#FFF" stroke-width="2" points="14.4444444 16.6666667 20 16.6666667 20 3.33333333 5.55555556 3.33333333"/><polygon id="Path-2" fill="#FFF" points="5.55555556 0 5.55555556 6.66666667 1.11111111 3.33333333"/><text id="10" font-family="HelveticaNeue, Helvetica Neue" font-size="10" font-weight="normal" fill="#FFF"><tspan x="0" y="17.333">10</tspan></text></g></g></svg>
`;

const image$3 = html`
<svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" transform="matrix(-1 0 0 1 20 0)"><path d="m14.4444444 16.6666667h5.5555556v-13.33333337h-14.44444444" stroke="#fff" stroke-width="2"/><g fill="#fff"><path d="m5.55555556 0v6.66666667l-4.44444445-3.33333334z"/><text font-family="HelveticaNeue, Helvetica Neue" font-size="10" transform="matrix(-1 0 0 1 13.333334 0)"><tspan x="0" y="17.333333">10</tspan></text></g></g></svg>
`;

const image$4 = html`
<svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path d="m34.5 18 12.5 25h-25z" fill="#333" fill-rule="evenodd" transform="matrix(0 1 -1 0 65 -4)"/></svg>
`;

const image$5 = html`
<svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg"><path d="m32 18h6v25h-6zm-10 0h6v25h-6z" fill="#333" fill-rule="evenodd"/></svg>
`;

const image$6 = html`
<svg xmlns="http://www.w3.org/2000/svg" width="23" height="17" viewBox="0 0 23 17">
  <g fill="none" fill-rule="evenodd" stroke="#FFF" transform="translate(1 1)">
    <g stroke-linecap="round" transform="translate(2 2.522)">
      <path d="M8.5,9.97826087 L15.5,2.97826087" transform="rotate(180 12 6.478)"/>
      <path d="M15.5,11.2695652 L15.5,8.26956522" transform="rotate(90 15.5 9.77)"/>
      <path d="M16.1555266,5.77496471 L16.1480288,4.77499282" transform="rotate(-120 16.152 5.275)"/>
      <path d="M12.9236091,2.48093451 L12.9161756,1.48096213" transform="rotate(-150 12.92 1.98)"/>
      <path d="M8.5,3.47826087 L8.5,0.47826087" transform="rotate(180 8.5 1.978)"/>
      <path d="M4.07639089,2.48093451 L4.08382438,1.48096213" transform="rotate(150 4.08 1.98)"/>
      <path d="M0.844473378,5.77496471 L0.851971223,4.77499282" transform="rotate(120 .848 5.275)"/>
      <path d="M1.5,11.2695652 L1.5,8.26956522" transform="rotate(90 1.5 9.77)"/>
    </g>
    <path stroke-width="2" d="M10.5,14.9867607 C22.0227273,14.9867607 20.9752066,15.5091468 20.9752066,9.99117383 C20.9752066,4.47320089 16.2852969,0 10.5,0 C4.71470314,0 0.0247933884,4.47320089 0.0247933884,9.99117383 C0.0247933884,15.5091468 -1.02272727,14.9867607 10.5,14.9867607 Z"/>
  </g>
</svg>
`;

const image$7 = html`
<svg height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="#fff" stroke-width="2"><path d="m14 3c2 2.05128205 3 4.71794872 3 8 0 3.2820513-1 5.9487179-3 8m4-18c2 2.56410256 3 5.8974359 3 10s-1 7.4358974-3 10" stroke-linecap="round"/><path d="m10 3.5-4 3.81h-5v8.38h5l4 3.81z" fill="#fff" stroke-linejoin="round"/></g></svg>
`;

const image$8 = html`
<svg height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="#fff" stroke-width="2"><path d="m14 3c2 2.05128205 3 4.71794872 3 8 0 3.2820513-1 5.9487179-3 8" stroke-linecap="round"/><path d="m18 1c2 2.56410256 3 5.8974359 3 10s-1 7.4358974-3 10" stroke-linecap="round" stroke-opacity=".1"/><path d="m10 3.5-4 3.81h-5v8.38h5l4 3.81z" fill="#fff" stroke-linejoin="round"/></g></svg>
`;

const image$9 = html`
<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><g fill="none" fill-rule="evenodd"><path stroke="#FFF" stroke-linecap="round" stroke-width="2" d="M16,7 L16,15" transform="rotate(-45 16 11)"/><path stroke="#FFF" stroke-linecap="round" stroke-width="2" d="M16,7 L16,15" transform="rotate(-135 16 11)"/><polygon fill="#FFF" stroke="#FFF" stroke-linejoin="round" stroke-width="2" points="10 3.5 6 7.31 1 7.31 1 15.69 6 15.69 10 19.5"/></g></svg>
`;

var __decorate$4 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let PlaybackControls = class PlaybackControls extends LitElement {
  constructor() {
    super(...arguments);
    this.playbackMode = PlaybackMode.paused;
    this.playbackRate = 1;
    this.volume = 1;
  }

  render() {
    return html`
      <div class="container">
        <div class="vertical-button-stack playback-speed">
          <div class="vertical-button-container">
            <button class="unstyled-button" @click="${this.handlePlaybackRateChange}">
              ${image$6}
            </button>
          </div>
          <div class="vertical-button-value">
            ${this.playbackRate}x
          </div>
        </div>
        <button id="prev-section-btn" class="jump-btn unstyled-button" @click="${this.handlePrevSectionButton}">
          ${image$1}
        </button>
        <button id="back-btn" class="jump-btn unstyled-button" @click="${this.handleBackButton}">
          ${image$2}
        </button>
        <button id="play-pause-btn" @click="${this.handlePlayPauseButton}">
          ${this.playPauseButtonImage}
        </button>
        <button id="forward-btn" class="jump-btn unstyled-button" @click="${this.handleForwardButton}">
          ${image$3}
        </button>
        <button id="next-section-btn" class="jump-btn unstyled-button" @click="${this.handleNextSectionButton}">
          ${image}
        </button>
        <div class="vertical-button-stack volume">
          <div class="vertical-button-container">
            <button class="unstyled-button" @click="${this.handleVolumeChange}">
              ${this.volumeButtonImage}
            </button>
          </div>
          <div class="vertical-button-value">
            ${this.volume * 100}%
          </div>
        </div>
      </div>
    `;
  }

  get playPauseButtonImage() {
    var image = image$4;

    switch (this.playbackMode) {
      case PlaybackMode.playing:
        image = image$5;
        break;

      case PlaybackMode.paused:
        image = image$4;
        break;
    }

    return image;
  }

  get volumeButtonImage() {
    var image = image$8;

    if (this.volume === 0) {
      image = image$9;
    }

    if (this.volume === 1) {
      image = image$7;
    }

    return image;
  }

  handlePlaybackRateChange() {
    if (this.playbackRate === 2.0) {
      this.playbackRate = 0.5;
    } else {
      this.playbackRate += 0.25;
    }

    const event = new CustomEvent('playbackRateChange', {
      detail: {
        playbackRate: this.playbackRate
      }
    });
    this.dispatchEvent(event);
  }

  handleVolumeChange() {
    if (this.volume === 1) {
      this.volume = 0;
    } else {
      this.volume += 0.25;
    }

    const event = new CustomEvent('volumeChange', {
      detail: {
        volume: this.volume
      }
    });
    this.dispatchEvent(event);
  }

  handleBackButton() {
    const event = new Event('back-button-pressed');
    this.dispatchEvent(event);
  }

  handlePrevSectionButton() {
    const event = new Event('prev-section-button-pressed');
    this.dispatchEvent(event);
  }

  handleNextSectionButton() {
    const event = new Event('next-section-button-pressed');
    this.dispatchEvent(event);
  }

  handlePlayPauseButton() {
    this.playbackMode = this.playbackMode === PlaybackMode.playing ? PlaybackMode.paused : PlaybackMode.playing;
    const event = new Event('play-pause-button-pressed');
    this.dispatchEvent(event);
  }

  handleForwardButton() {
    const event = new Event('forward-button-pressed');
    this.dispatchEvent(event);
  }

  static get styles() {
    const playPauseDiameterCss = css`var(--playPauseDiameter, 4rem)`;
    return css`
      :host {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }

      .container {
        display: flex;
        justify-content: space-between;
        color: white;
        width: 100%;
      }

      .vertical-button-stack {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .vertical-button-container {
        text-align: center;
      }

      .vertical-button-container button {
        vertical-align: bottom;
      }

      .vertical-button-container svg {
        vertical-align: bottom;
      }

      .vertical-button-value {
        font-size: 0.7em;
        line-height: 1.4em;
        text-align: center;
      }

      #play-pause-btn {
        border-radius: 50%;
        height: ${playPauseDiameterCss};
        width: ${playPauseDiameterCss};
        border: none;
        background-color: white;
        vertical-align: middle;
      }

      #play-pause-btn:active {
        background-color: rgba(255, 255, 255, 0.75);
      }

      #play-pause-btn svg {
        width: 100%;
        height: 100%;
      }

      .unstyled-button {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
      }

      button {
        cursor: pointer;
      }

      .jump-btn:active img {
        opacity: 0.75;
      }
    `;
  }

};

__decorate$4([property({
  type: PlaybackMode
})], PlaybackControls.prototype, "playbackMode", void 0);

__decorate$4([property({
  type: Number
})], PlaybackControls.prototype, "playbackRate", void 0);

__decorate$4([property({
  type: Number
})], PlaybackControls.prototype, "volume", void 0);

PlaybackControls = __decorate$4([customElement('playback-controls')], PlaybackControls);

const image$a = html`
<svg height="10" viewBox="0 0 8 10" width="8" xmlns="http://www.w3.org/2000/svg"><path d="m4 1 5 8h-10z" fill="#fff" fill-rule="evenodd" transform="matrix(0 -1 1 0 -1 9)"/></svg>
`;

const image$b = html`
<svg height="10" viewBox="0 0 8 10" width="8" xmlns="http://www.w3.org/2000/svg"><path d="m4 1 5 8h-10z" fill="#fff" fill-rule="evenodd" transform="matrix(0 1 -1 0 9 1)"/></svg>
`;

var __decorate$5 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SectionMarkerMode;

(function (SectionMarkerMode) {
  SectionMarkerMode["left"] = "left";
  SectionMarkerMode["right"] = "right";
  SectionMarkerMode["both"] = "both";
  SectionMarkerMode["neither"] = "neither";
})(SectionMarkerMode || (SectionMarkerMode = {}));
let SectionMarker = class SectionMarker extends LitElement {
  constructor() {
    super(...arguments);
    this.markerMode = SectionMarkerMode.neither;
  }

  render() {
    return html`
      <div class="container mode-${this.markerMode}">
        <div class="left-arrow arrow">${image$b}</div>
        <div class="center-divider"></div>
        <div class="right-arrow arrow">${image$a}</div>
      </div>
    `;
  }

  static get styles() {
    const animationSpeed = css`0.1s`;
    const markerHeightCollapsedCss = css`var(--markerHeightCollapsed, 10px)`;
    const markerHeightExpandedCss = css`var(--markerHeightExpanded, 25px)`;
    return css`
      .container {
        display: flex;
        justify-content: center;
        height: 100%;
      }

      .arrow {
        padding-top: 10px;
        opacity: 1;
        transition: opacity ${animationSpeed} ease-out, padding-top ${animationSpeed} ease-out;
      }

      .right-arrow {
        visibility: hidden;
      }

      .arrow {
        visibility: hidden;
      }

      .container.mode-left .right-arrow {
        opacity: 0;
      }

      .container.mode-right .left-arrow {
        opacity: 0;
      }

      .container.mode-neither .left-arrow, .container.mode-neither .right-arrow {
        opacity: 0;
        padding-top: 75%;
      }

      .container.mode-neither .center-divider {
        height: ${markerHeightCollapsedCss};
      }

      .center-divider {
        border-left: 1px solid white;
        width: 1px;
        left: 50%;
        height: ${markerHeightExpandedCss};
        align-self: flex-end;
        transition: height ${animationSpeed} ease-out;
      }
    `;
  }

};

__decorate$5([property({
  type: SectionMarkerMode
})], SectionMarker.prototype, "markerMode", void 0);

SectionMarker = __decorate$5([customElement('section-marker')], SectionMarker);

var __decorate$6 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let ScrubberBar = class ScrubberBar extends LitElement {
  constructor() {
    super(...arguments);
    this.value = 0;
    this.min = 0;
    this.max = 100;
    this.step = 0.1;
    this.sectionMarkerPercentages = [];
    this.expandSectionMarkers = false;
    this._userInteracting = false; // This is the canonical source for the current value. Since the value can be updated by either the consumer
    // or the user, we need a single place for the actual value. It is non-reactive so we can update it in either
    // scenario without causing a loop of `value` updates.

    this._value = 0;
  }

  get percentage() {
    const delta = this.max - this.min;
    const minOffset = this._value - this.min;
    return minOffset / delta * 100;
  }

  render() {
    return html`
      <div class="container">
        <div class="color-fill">
        </div>

        <div class="marker-container">
        ${this.sectionMarkerPercentages.map(markerPercent => {
      return html`
            <section-marker
              data-location=${markerPercent}
              style="left: ${markerPercent}%">
            </section-marker>
          `;
    })}
        </div>

        <input
          id="slider"
          type="range"
          min=${this.min}
          max=${this.max}
          step=${this.step}
          value=${this.value}
          @mousedown=${this.interactionStarted}
          @mouseup=${this.interactionEnded}
          @touchstart=${this.interactionStarted}
          @touchend=${this.interactionEnded}
          @input=${this.handleSlide}
          @change=${this.handleSlide}
        />

        <div id="webkit-range-input-style"></div>
      </div>
    `;
  }

  updated(changedProperties) {
    if (this._userInteracting || !changedProperties.has('value')) {
      return;
    }

    this._value = this.value;

    if (this.rangeSlider) {
      this.rangeSlider.value = `${this.value}`;
    }

    this.updateWebkitSliderStyle();
    this.updateMarkerFlags();
  }

  firstUpdated() {
    this.updateWebkitSliderStyle();
    this.updateMarkerFlags();
  }

  handleSlide(e) {
    const newValue = e.target.value;
    this._value = parseFloat(newValue);
    this.updateWebkitSliderStyle();
    this.updateMarkerFlags();
    this.emitChangeEvent();
  }

  interactionStarted() {
    this._userInteracting = true;
    this.dispatchEvent(new Event('userInteractionStarted'));
  }

  interactionEnded() {
    this._userInteracting = false;
    this.dispatchEvent(new Event('userInteractionEnded'));
  }

  get rangeSlider() {
    return this.shadowRoot && this.shadowRoot.getElementById('slider');
  }

  get webkitStyle() {
    return this.shadowRoot && this.shadowRoot.getElementById('webkit-range-input-style');
  }

  updateWebkitSliderStyle() {
    if (!this.webkitStyle) {
      return;
    }

    this.webkitStyle.innerHTML = `
      <style>
        .color-fill {
          background: linear-gradient(to right,
            var(--trackFillColor, #3272b6) 0%, var(--trackFillColor, #3272b6) ${this.percentage}%,
            var(--trackColor, rgba(0, 0, 0, 0.1)) ${this.percentage}%, var(--trackColor, rgba(0, 0, 0, 0.1)) 100%);
        }
      </style>
    `;
  }

  emitChangeEvent() {
    const event = new CustomEvent('valuechange', {
      detail: {
        value: this._value
      }
    });
    this.dispatchEvent(event);
  }

  get sortedMarkers() {
    return this.sectionMarkerPercentages.sort();
  }

  updateMarkerFlags() {
    if (!this.expandSectionMarkers) {
      return;
    }

    const currentValue = this._value;
    const percentsGreaterThanValue = this.sortedMarkers.filter(value => value > currentValue);
    const closestUpper = Math.min(...percentsGreaterThanValue);
    const percentsLessThanValue = this.sortedMarkers.filter(value => value <= currentValue);
    const closestLower = Math.max(...percentsLessThanValue);
    this.sectionMarkerPercentages.forEach(value => {
      if (!this.shadowRoot) {
        return;
      }

      const marker = this.shadowRoot.querySelector(`section-marker[data-location="${value}"]`);

      if (!marker) {
        return;
      }

      switch (value) {
        case closestUpper:
          marker.markerMode = SectionMarkerMode.left;
          break;

        case closestLower:
          marker.markerMode = SectionMarkerMode.right;
          break;

        default:
          marker.markerMode = SectionMarkerMode.neither;
      }
    });
  }

  static get styles() {
    const markerInset = css`var(--markerInset, 10px)`;
    const scrubberBarHeight = css`var(--scrubberBarHeight, 20px)`;
    const thumbDiameter = css`var(--thumbDiameter, 20px)`;
    const thumbBorderRadius = css`var(--thumbBorderRadius, 50%)`;
    const thumbBorder = css`var(--thumbBorder, 1px solid black)`;
    const thumbColor = css`var(--thumbColor, white)`;
    const trackHeight = css`var(--trackHeight, 10px)`;
    const trackBorderRadius = css`var(--trackBorderRadius, 5px)`;
    const trackBorder = css`var(--trackBorder, 1px solid white)`;
    const trackFillColor = css`var(--trackFillColor, #3272b6)`;
    const trackColor = css`var(--trackColor, rgba(0, 0, 0, 0.1))`;
    const webkitThumbTopMargin = css`var(--webkitThumbTopMargin, -6px)`;
    const commonThumbDefinitions = css`
      background-color: ${thumbColor};
      height: ${thumbDiameter};
      width: ${thumbDiameter};
      border-radius: ${thumbBorderRadius};
      border: ${thumbBorder};
      cursor: pointer;
    `;
    const trackSizeDefinitions = css`
      height: ${trackHeight};
      border-radius: ${trackBorderRadius};
    `;
    const commonTrackDefinitions = css`
      border: ${trackBorder};
      ${trackSizeDefinitions};
    `;
    return css`
      .container {
        position: relative;
        height: 20px;
      }

      .color-fill {
        height: 10px;
        border-radius: 1em;
        position: absolute;
        bottom: 7px;
        left: 2px;
        right: -2px;
      }

      .marker-container {
        position: absolute;
        left: ${markerInset};
        right: ${markerInset};
        height: 100%;
      }

      section-marker {
        position: absolute;
        width: 2rem;
        height: 40px;
        bottom: 7px;
        /*
          we set the left side of the marker to the spot where we want it, but the marker line is in
          the center of the marker so we need to shift it to the left by half its width so this transform
          is doing that
         */
        transform: translateX(-50%);
      }

      input[type='range'] {
        -webkit-appearance: none;
        height: ${scrubberBarHeight};
        padding: 0;
        width: 100%;
        background: none;
        outline: none;
        position: absolute;
        bottom: 0;
      }

      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        box-sizing: content-box;
        margin-top: ${webkitThumbTopMargin};
        ${commonThumbDefinitions}
      }

      input[type='range']::-moz-range-thumb {
        ${commonThumbDefinitions}
      }

      input[type='range']::-ms-thumb {
        /* should come after -webkit- */
        ${commonThumbDefinitions}
        margin-top: 0;
      }

      input[type='range']::-webkit-slider-runnable-track {
        ${commonTrackDefinitions}
      }

      input[type='range']::-moz-range-track {
        ${commonTrackDefinitions}
      }

      input[type='range']::-moz-range-progress {
        ${trackSizeDefinitions};
      }

      input[type='range']::-ms-track {
        /* should come after -webkit- */
        border-color: transparent;
        color: transparent;
        ${commonTrackDefinitions}
      }

      input[type='range']::-ms-fill-lower {
        background-color: ${trackFillColor};
        ${trackSizeDefinitions};
      }

      input[type='range']::-ms-tooltip {
        display: none;
      }
    `;
  }

};

__decorate$6([property({
  type: Number
})], ScrubberBar.prototype, "value", void 0);

__decorate$6([property({
  type: Number
})], ScrubberBar.prototype, "min", void 0);

__decorate$6([property({
  type: Number
})], ScrubberBar.prototype, "max", void 0);

__decorate$6([property({
  type: Number
})], ScrubberBar.prototype, "step", void 0);

__decorate$6([property({
  type: Array
})], ScrubberBar.prototype, "sectionMarkerPercentages", void 0);

__decorate$6([property({
  type: Boolean
})], ScrubberBar.prototype, "expandSectionMarkers", void 0);

ScrubberBar = __decorate$6([customElement('scrubber-bar')], ScrubberBar);

const image$c = html`
  <svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m5.17668106 0c-2.859002 0-5.17668106 2.31767906-5.17668106 5.17668106 0 2.85900201 2.31767906 5.17668104 5.17668106 5.17668104.98756168.0210349 1.96052598-.240917 2.80403558-.75493263l4.09820586 4.20605333 1.7255603-1.7255603-4.20605333-4.09820586c.51401563-.8435096.77596753-1.8164739.75493263-2.80403558 0-2.859002-2.31767903-5.17668106-5.17668104-5.17668106m0 1.72556035c1.90600134 0 3.45112071 1.54511938 3.45112071 3.45112071 0 1.90600134-1.54511937 3.45112071-3.45112071 3.45112071-1.90600133 0-3.45112071-1.54511937-3.45112071-3.45112071 0-1.90600133 1.54511938-3.45112071 3.45112071-3.45112071"
      fill="#fff"
    />
  </svg>
`;

const image$d = html`
  <svg height="9" viewBox="0 0 19 9" width="19" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m1 1 9 7 8-7"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
`;

const image$e = html`
<svg height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle cx="6" cy="6" fill="#fff" r="6"/><g stroke="#000" stroke-linecap="round"><path d="m3.375 3.375 5.18412641 5.18412641"/><path d="m3.375 3.375 5.18412641 5.18412641" transform="matrix(-1 0 0 1 12 0)"/></g></g></svg>
`;

var __decorate$7 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let SearchBar = class SearchBar extends LitElement {
  constructor() {
    super(...arguments);
    this.isOpen = false;
    this.showsDisclosure = false;
    this.searchTerm = '';
    this.quickSearches = [];
  }

  render() {
    return html`
      <div
        class="
          container
          ${this.isOpen ? 'is-open' : ''}
          ${this.showsDisclosure ? 'shows-disclosure' : ''}"
      >
        <div class="search-bar">
          <div class="magnifier-container endcap">
            ${image$c}
          </div>
          <input
            type="text"
            class="search-input"
            placeholder="Search"
            value=${this.searchTerm}
            @keyup=${this.inputChanged}
          />
          <div
            class="clear-search-container endcap ${this.searchTerm === '' ? '' : 'is-searching'}"
          >
            <button @click=${this.clearSearch}>
              ${image$e}
            </button>
          </div>
          <div class="disclosure-container endcap">
            <button @click=${this.toggleDisclosure}>
              ${image$d}
            </button>
          </div>
        </div>
        <div class="quick-search">
          <quick-search
            .quickSearches=${this.quickSearches}
            @searchTermSelected=${this.doQuickSearch}
          >
          </quick-search>
        </div>
      </div>
    `;
  }

  clearSearch() {
    this.searchTerm = '';
    this.emitSearchClearedEvent();
  }

  inputChanged(e) {
    this.emitInputChangeEvent();

    if (e.key === 'Enter') {
      this.emitEnterKeyPressedEvent();
    }
  }

  emitInputChangeEvent() {
    const value = this.searchInput && this.searchInput.value;
    const event = new CustomEvent('inputchange', {
      detail: {
        value
      }
    });
    this.dispatchEvent(event);
  }

  emitEnterKeyPressedEvent() {
    const value = this.searchInput && this.searchInput.value || '';
    const event = new CustomEvent('enterKeyPressed', {
      detail: {
        value
      }
    });
    this.dispatchEvent(event);
  }

  emitSearchClearedEvent() {
    const event = new Event('searchCleared');
    this.dispatchEvent(event);
  }

  doQuickSearch(e) {
    this.searchTerm = e.detail.searchTerm;
    this.isOpen = false;
  }

  toggleDisclosure() {
    this.isOpen = !this.isOpen;
  }

  get searchInput() {
    return this.shadowRoot && this.shadowRoot.querySelector('.search-input');
  }

  updateSearchChange() {
    if (!this.searchInput) {
      return;
    }

    this.searchInput.value = this.searchTerm;
    this.emitInputChangeEvent();
  }

  updated(changedProperties) {
    if (changedProperties.has('searchTerm')) {
      // for some reason, the input will not update automatically if the user has interacted with it
      // so this just sets it manually
      this.updateSearchChange();
    }
  }

  static get styles() {
    const searchBarBackgroundColorCss = css`var(--searchBarBackgroundColor, black)`;
    const searchBarTextColorCss = css`var(--searchBarTextColor, white)`;
    const searchBarFontSizeCss = css`var(--searchBarFontSize, 1em)`;
    const searchBarBorderCss = css`var(--searchBarBorderColor, 1px solid white)`;
    return css`
      .container {
        position: relative;
      }

      .search-bar {
        display: flex;
        justify-content: flex-start;
      }
      .endcap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2em;
        border: ${searchBarBorderCss};
        padding: 0 0.5em;
      }
      .endcap svg {
        height: 1.5em;
      }

      .clear-search-container {
        border-left: 0;
        border-radius: 0 1em 1em 0;
      }

      .clear-search-container.is-searching {
        padding: 0 0.5em 0 0;
      }

      .clear-search-container button {
        display: none;
      }

      .clear-search-container.is-searching button {
        display: block;
      }

      .magnifier-container {
        border-radius: 1em 0 0 1em;
        border-right: 0;
      }
      .container.is-open.shows-disclosure .clear-search-container {
        border-radius: 1em 0 0 0;
      }
      .disclosure-container {
        border-radius: 0 1em 1em 0;
        display: none;
      }
      .container.shows-disclosure .disclosure-container {
        display: flex;
      }
      .container.is-open .disclosure-container {
        border-radius: 0 1em 0 0;
      }
      .disclosure-container button {
        border: 0;
        background: none;
      }

      .search-input {
        height: 2em;
        border-top: ${searchBarBorderCss};
        border-bottom: ${searchBarBorderCss};
        border-left: 0;
        border-right: 0;
        background-color: ${searchBarBackgroundColorCss};
        color: ${searchBarTextColorCss};
        padding: 0;
        margin: 0;
        font-size: ${searchBarFontSizeCss};
        flex: 1 1 auto;
        min-width: 5em;
      }

      .search-input:focus {
        outline: none;
      }

      .quick-search {
        color: white;
        border-radius: 0 0 1em 1em;
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        background-color: black;
        z-index: 1;
        max-height: 150px;
        overflow-y: scroll;
        scrollbar-width: none;
        padding: 0 0.5em;
      }

      .quick-search::-webkit-scrollbar {
        display: none;
      }

      .container.is-open.shows-disclosure .quick-search {
        border: 1px solid white;
        border-top: 0;
        display: block;
      }

      button {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
      }
    `;
  }

};

__decorate$7([property({
  type: Boolean
})], SearchBar.prototype, "isOpen", void 0);

__decorate$7([property({
  type: Boolean
})], SearchBar.prototype, "showsDisclosure", void 0);

__decorate$7([property({
  type: String
})], SearchBar.prototype, "searchTerm", void 0);

__decorate$7([property({
  type: Array
})], SearchBar.prototype, "quickSearches", void 0);

SearchBar = __decorate$7([customElement('search-bar')], SearchBar);

var __decorate$8 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let QuickSearch = class QuickSearch extends LitElement {
  constructor() {
    super(...arguments);
    this.quickSearches = [];
  }

  render() {
    return html`
      <ul>
        ${this.quickSearches.map(quickSearch => html`
            <li>
              <a @click=${this.doQuickSearch} data-search-term=${quickSearch}>${quickSearch}</a>
            </li>
          `)}
      </ul>
    `;
  }

  doQuickSearch(e) {
    const {
      searchTerm
    } = e.target.dataset;

    if (searchTerm) {
      const event = new CustomEvent('searchTermSelected', {
        detail: {
          searchTerm
        },
        bubbles: true,
        composed: true
      });
      this.dispatchEvent(event);
    }
  }

  static get styles() {
    return css`
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      ul li {
        padding: 0.25em 0 0 0;
        margin: 0;
        display: block;
      }

      ul li a {
        color: rgb(68, 132, 202);
        text-decoration: none;
        cursor: pointer;
      }
    `;
  }

};

__decorate$8([property({
  type: Array
})], QuickSearch.prototype, "quickSearches", void 0);

QuickSearch = __decorate$8([customElement('quick-search')], QuickSearch);

const image$f = html`
  <svg height="13" viewBox="0 0 8 13" width="8" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m-1.5 8.5 5-5 5 5"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      transform="matrix(0 -1 1 0 -2 9.7)"
    />
  </svg>
`;

const image$g = html`
  <svg height="13" viewBox="0 0 8 13" width="8" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m-1.5 8.5 5-5 5 5"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      transform="matrix(0 -1 -1 0 10 9.7)"
    />
  </svg>
`;

var __decorate$9 = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let SearchResultsSwitcher = class SearchResultsSwitcher extends LitElement {
  constructor() {
    super(...arguments);
    this.numberOfResults = 0;
    this.currentResultIndex = 0;
  }

  render() {
    return html`
      <div class="container">
        <a @click=${this.goToPreviousResult}>${image$f}</a>
        <span class="results-range">${this.currentResultIndex + 1} / ${this.numberOfResults}</span>
        <a @click=${this.goToNextResult}>${image$g}</a>
      </div>
    `;
  }

  static get styles() {
    return css`
      .container {
        text-align: center;
      }

      a {
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    `;
  }

  updated(changedProperties) {
    if (changedProperties.has('numberOfResults')) {
      this.currentResultIndex = 0;
    }
  }

  goToPreviousResult() {
    if (this.currentResultIndex < 1) {
      this.currentResultIndex = this.numberOfResults - 1;
    } else {
      this.currentResultIndex -= 1;
    }

    this.emitSearchResultIndexChangedEvent();
  }

  goToNextResult() {
    if (this.currentResultIndex === this.numberOfResults - 1) {
      this.currentResultIndex = 0;
    } else {
      this.currentResultIndex += 1;
    }

    this.emitSearchResultIndexChangedEvent();
  }

  emitSearchResultIndexChangedEvent() {
    const event = new CustomEvent('searchResultIndexChanged', {
      detail: {
        searchResultIndex: this.currentResultIndex
      }
    });
    this.dispatchEvent(event);
  }

};

__decorate$9([property({
  type: Number
})], SearchResultsSwitcher.prototype, "numberOfResults", void 0);

__decorate$9([property({
  type: Number
})], SearchResultsSwitcher.prototype, "currentResultIndex", void 0);

SearchResultsSwitcher = __decorate$9([customElement('search-results-switcher')], SearchResultsSwitcher);

class MusicZone {
  constructor(start, end) {
    this.start = 0;
    this.end = 0;
    this.start = start;
    this.end = end;
  }

}

var __decorate$a = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let RadioPlayer = class RadioPlayer extends LitElement {
  constructor() {
    super(...arguments);
    this.config = undefined;
    this.transcriptConfig = undefined;
    this.currentTime = 0;
    this.searchTerm = '';
    this.skipMusicSections = false;
    this.percentComplete = 0;
    this.isPlaying = false;
    this.duration = 0;
    this.playbackRate = 1;
    this.volume = 1;
    this.shouldShowSearchResultSwitcher = false;
    this.shouldShowNoSearchResultMessage = false;
    this.musicZones = [];
  }

  render() {
    return html`
      ${this.audioElementTemplate}
      <section role="main">
        ${this.titleDateTemplate} ${this.collectionLogoTemplate} ${this.playbackControlsTemplate}
        <div class="waveform-scrubber-container">
          ${this.waveFormProgressTemplate} ${this.scrubberBarTemplate}
        </div>
        ${this.searchSectionTemplate} ${this.transcriptViewTemplate}
      </section>
    `;
  }

  play() {
    if (this.audioElement) this.audioElement.play();
  }

  pause() {
    if (this.audioElement) this.audioElement.pause();
  }

  seekTo(seconds) {
    if (this.audioElement) this.audioElement.seekTo(seconds);
  }

  get titleDateTemplate() {
    return html`
      <div class="title-date">
        <div class="title">
          ${this.config ? this.config.title : ''}
        </div>

        <div class="date">
          ${this.config ? this.config.date : ''}
        </div>
      </div>
    `;
  }

  get collectionLogoTemplate() {
    return html`
      <img class="collection-logo" src=${this.logoUrl} />
    `;
  }

  get logoUrl() {
    return this.config ? this.config.logoUrl : '';
  }

  get waveFormProgressTemplate() {
    return html`
      <waveform-progress
        interactive="true"
        .waveformUrl=${this.waveformUrl}
        .percentComplete=${this.percentComplete}
        @valuechange=${this.valueChangedFromScrub}
      >
      </waveform-progress>
    `;
  }

  get zonesOfSilence() {
    if (this.duration === 0) {
      return [];
    } // eslint-disable-next-line max-len


    const musicEntries = this.transcriptEntries.filter(entry => entry.isMusic === true);
    const zonesOfSilence = musicEntries.map(entry => {
      const startPercent = entry.start / this.duration * 100;
      const endPercent = entry.end / this.duration * 100;
      return new ZoneOfSilence(startPercent, endPercent);
    });
    return zonesOfSilence;
  }

  get waveformUrl() {
    return this.config ? this.config.waveformUrl : '';
  }

  get audioElementTemplate() {
    return html`
      <audio-element
        .sources=${this.audioSources}
        .playbackRate=${this.playbackRate}
        .volume=${this.volume}
        @timeupdate=${this.handleTimeChange}
        @durationchange=${this.handleDurationChange}
        @playbackStarted=${this.playbackStarted}
        @playbackPaused=${this.playbackPaused}
        @canplay=${this.canplay}
      >
      </audio-element>
    `;
  }

  get audioSources() {
    return this.config ? this.config.audioSources : [];
  }

  get playbackControlsTemplate() {
    return html`
      <playback-controls
        @back-button-pressed=${this.backButtonHandler}
        @play-pause-button-pressed=${this.playPauseButtonHandler}
        @forward-button-pressed=${this.forwardButtonHandler}
        @volumeChange=${this.volumeChanged}
        @playbackRateChange=${this.changePlaybackRate}
        @next-section-button-pressed=${this.nextSectionButtonHandler}
        @prev-section-button-pressed=${this.prevSectionButtonHandler}
      >
      </playback-controls>
    `;
  }

  get scrubberBarMarkerPercentages() {
    var percentages = [0];
    this.zonesOfSilence.forEach(zone => {
      percentages.push(zone.startPercent);
      percentages.push(zone.endPercent);
    });
    percentages.push(100);
    return percentages;
  }

  get scrubberBarTemplate() {
    return html`
      <scrubber-bar
        .sectionMarkerPercentages=${this.scrubberBarMarkerPercentages}
        .value=${this.percentComplete}
        @valuechange=${this.valueChangedFromScrub}
      >
      </scrubber-bar>
    `;
  }

  get transcriptViewTemplate() {
    return html`
      <div class="transcript-container">
        <transcript-view
          .config=${this.transcriptConfig}
          .currentTime=${this.currentTime}
          @transcriptEntrySelected=${this.transcriptEntrySelected}
        >
        </transcript-view>
      </div>
    `;
  }

  get searchSectionTemplate() {
    // The mobile and desktop search sections work similarly, but the mobile version has
    // a dropdown area on it and the desktop version does not.
    // This is a case where the functionality is different enough to have two instances
    // of it instead of one and just show and hide them based on the media query.
    return html`
      <div class="search-section">
        <search-bar
          searchTerm=${this.searchTerm}
          .quickSearches=${this.quickSearches}
          @inputchange=${this.updateSearchTerm}
          @enterKeyPressed=${this.searchEnterKeyPressed}
          @searchCleared=${this.searchCleared}
        >
        </search-bar>
        <div class="search-results-info">
          ${this.searchResultsSwitcherTemplate} ${this.noSearchResultsTemplate}
        </div>
      </div>
    `;
  }

  get searchResultsSwitcherTemplate() {
    return html`
      <search-results-switcher
        class="${this.shouldShowSearchResultSwitcher ? '' : 'hidden'}"
        @searchResultIndexChanged=${this.searchResultIndexChanged}
      >
      </search-results-switcher>
    `;
  }

  get noSearchResultsTemplate() {
    return html`
      <div
        class="no-search-results-message ${this.shouldShowNoSearchResultMessage ? '' : 'hidden'}"
      >
        No search results.
      </div>
    `;
  }

  get quickSearches() {
    return this.config ? this.config.quickSearches : [];
  }

  updateSearchTerm(e) {
    this.searchTerm = e.detail.value;
  }

  searchCleared() {
    this.searchTerm = '';
    this.emitSearchClearedEvent();

    if (this.transcriptView) {
      this.transcriptView.selectedSearchResultIndex = 0;
    }

    if (this.searchResultsSwitcher) {
      this.searchResultsSwitcher.currentResultIndex = 0;
    }
  }

  emitSearchClearedEvent() {
    const event = new Event('searchCleared');
    this.dispatchEvent(event);
  }

  searchResultIndexChanged(e) {
    if (!this.transcriptView) {
      return;
    }

    this.transcriptView.selectedSearchResultIndex = e.detail.searchResultIndex;
    this.transcriptView.scrollToSelectedSearchResult();
  }

  searchEnterKeyPressed(e) {
    const event = new CustomEvent('searchRequested', {
      detail: {
        searchTerm: e.detail.value
      }
    });
    this.dispatchEvent(event);
  }

  get transcriptEntries() {
    return this.transcriptConfig ? this.transcriptConfig.entries : [];
  }

  get transcriptView() {
    return this.shadowRoot ? this.shadowRoot.querySelector('transcript-view') : null;
  }

  get audioElement() {
    return this.shadowRoot ? this.shadowRoot.querySelector('audio-element') : null;
  }

  get playbackControls() {
    return this.shadowRoot ? this.shadowRoot.querySelector('playback-controls') : null;
  }

  get searchResultsSwitcher() {
    return this.shadowRoot ? this.shadowRoot.querySelector('search-results-switcher') : null;
  }

  changePlaybackRate(e) {
    this.playbackRate = e.detail.playbackRate;
  }

  volumeChanged(e) {
    this.volume = e.detail.volume;
  }

  backButtonHandler() {
    if (this.audioElement) {
      this.audioElement.seekBy(-10);
    }
  }

  playPauseButtonHandler() {
    this.isPlaying = !this.isPlaying;

    if (!this.audioElement) {
      return;
    }

    if (this.isPlaying) {
      this.audioElement.play();
    } else {
      this.audioElement.pause();
    }
  }

  forwardButtonHandler() {
    if (this.audioElement) {
      this.audioElement.seekBy(10);
    }
  }

  nextSectionButtonHandler() {
    if (!this.audioElement) {
      return;
    }

    const percentsGreaterThanValue = this.scrubberBarMarkerPercentages.filter(value => value > this.percentComplete + 0.1);
    const closestUpper = Math.min(...percentsGreaterThanValue);
    const seekTo = this.duration * (closestUpper / 100) + 0.1;
    this.audioElement.seekTo(seekTo);
  }

  prevSectionButtonHandler() {
    if (!this.audioElement) {
      return;
    }

    const percentsLessThanValue = this.scrubberBarMarkerPercentages.filter(value => value < this.percentComplete - 0.1);
    const closestLower = Math.max(...percentsLessThanValue);
    const seekTo = this.duration * (closestLower / 100) - 0.1;
    this.audioElement.seekTo(seekTo);
  }

  handleDurationChange(e) {
    this.duration = e.detail.duration;
  }

  handleTimeChange(e) {
    this.currentTime = e.detail.currentTime;
    const percent = this.currentTime / this.duration;
    this.percentComplete = percent * 100;
  }

  emitCurrentTimeChangedEvent() {
    const event = new CustomEvent('currentTimeChanged', {
      detail: {
        currentTime: this.currentTime
      }
    });
    this.dispatchEvent(event);
  }

  playbackPaused() {
    this.isPlaying = false;

    if (this.playbackControls) {
      this.playbackControls.playbackMode = PlaybackMode.paused;
    }

    const event = new Event('playbackPaused');
    this.dispatchEvent(event);
  }

  playbackStarted() {
    this.isPlaying = true;

    if (this.playbackControls) {
      this.playbackControls.playbackMode = PlaybackMode.playing;
    }

    const event = new Event('playbackStarted');
    this.dispatchEvent(event);
  }

  canplay() {
    const event = new Event('canplay');
    this.dispatchEvent(event);
  }

  valueChangedFromScrub(e) {
    const percentage = e.detail.value;
    const newTime = this.duration * (percentage / 100);
    this.currentTime = newTime;

    if (this.audioElement) {
      this.audioElement.seekTo(newTime);
    }

    this.percentComplete = percentage;
    const event = new CustomEvent('timeChangedFromScrub', {
      detail: {
        newTime: this.currentTime
      }
    });
    this.dispatchEvent(event);
  }

  transcriptEntrySelected(e) {
    const newTime = e.detail.entry.start;
    this.currentTime = newTime;

    if (this.audioElement) {
      this.audioElement.seekTo(newTime);
      this.audioElement.play();
    }

    const event = new CustomEvent('transcriptEntrySelected', {
      detail: {
        newTime: this.currentTime
      }
    });
    this.dispatchEvent(event);
  }

  updateMusicZones() {
    // eslint-disable-next-line max-len
    const musicEntries = this.transcriptEntries.filter(entry => entry.isMusic === true); // eslint-disable-next-line max-len

    const musicZones = musicEntries.map(entry => new MusicZone(entry.start, entry.end));
    this.musicZones = musicZones;
  }

  checkForMusicZone() {
    // eslint-disable-next-line max-len
    const activeMusicZone = this.musicZones.find(zone => this.currentTime > zone.start && this.currentTime < zone.end);

    if (activeMusicZone && this.audioElement) {
      this.audioElement.seekTo(activeMusicZone.end + 0.1);
    }
  }

  updateSearchResultSwitcher() {
    this.shouldShowNoSearchResultMessage = false;
    this.shouldShowSearchResultSwitcher = false;

    if (this.searchTerm.length === 0) {
      return;
    }

    const resultCount = this.searchResults.length;

    if (resultCount === 0) {
      this.shouldShowNoSearchResultMessage = true;
    } else {
      this.shouldShowSearchResultSwitcher = true;

      if (this.searchResultsSwitcher) {
        this.searchResultsSwitcher.numberOfResults = resultCount;
      }
    }
  }

  get searchResults() {
    return this.transcriptConfig ? this.transcriptConfig.searchResults : [];
  }

  updated(changedProperties) {
    if (changedProperties.has('transcriptConfig')) {
      this.updateMusicZones();
      this.updateSearchResultSwitcher();
    }

    if (changedProperties.has('currentTime')) {
      this.emitCurrentTimeChangedEvent();

      if (this.skipMusicSections) {
        this.checkForMusicZone();
      }
    }
  }

  static get styles() {
    const collectionLogoMaxHeightCss = css`var(--collectionLogoMaxHeight, 8rem)`;
    const titleColorCss = css`var(--titleColor, white)`;
    const titleFontCss = css`var(--titleFont, 1.5em sans-serif)`;
    const dateColorCss = css`var(--dateColor, white)`;
    const dateFontCss = css`var(--dateFont, 1em sans-serif)`;
    const waveformProgressHeightCss = css`var(--waveformProgressHeight, 5rem)`;
    return css`
      section[role='main'] {
        display: -ms-grid;
        display: grid;
        grid-gap: 0.5rem;
      }

      /* mobile view */
      @media (max-width: 770px) {
        section[role='main'] {
          -ms-grid-columns: 25% 0.5rem 1fr;
          -ms-grid-rows: auto 0.5rem auto 0.5rem auto 0.5rem auto 0.5rem auto;
          grid-template-columns: 25% 1fr;
          grid-template-areas:
            'collection-logo title-date'
            'waveform-scrubber waveform-scrubber'
            'playback-controls playback-controls'
            'search-section search-section'
            'transcript-container transcript-container';
        }
        .date {
          text-align: left;
        }
        transcript-view {
          --timeDisplay: none;
        }
        playback-controls {
          width: 75%;
          margin: auto;
          -ms-grid-row: 5;
          -ms-grid-column: 1;
          -ms-grid-column-span: 3;
        }
        .title-date {
          -ms-grid-row: 1;
          -ms-grid-column: 3;
        }
        .transcript-container {
          -ms-grid-row: 9;
          -ms-grid-column: 1;
          -ms-grid-column-span: 3;
        }
        .collection-logo {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
        .waveform-scrubber-container {
          -ms-grid-row: 3;
          -ms-grid-column: 1;
          -ms-grid-column-span: 3;
        }
        .search-section {
          -ms-grid-row: 7;
          -ms-grid-column: 1;
          -ms-grid-column-span: 3;
          width: 75%;
          margin: auto;
        }
        search-bar {
          width: 100%;
        }
      }

      /* wide view */
      @media (min-width: 770px) {
        section[role='main'] {
          -ms-grid-columns: 192px 0.5rem 0 0.5rem 250px 0.5rem 1fr;
          -ms-grid-rows: auto 0.5rem auto 0.5rem auto;
          grid-template-columns: 192px 0 250px 1fr;
          grid-template-areas:
            'title-date title-date title-date title-date'
            'collection-logo 1 playback-controls waveform-scrubber'
            'search-section transcript-container transcript-container transcript-container';
        }
        .title-date {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          -ms-grid-row: 1;
          -ms-grid-column: 1;
          -ms-grid-column-span: 7;
        }
        transcript-view {
          --timeDisplay: block;
        }
        playback-controls {
          -ms-grid-row: 3;
          -ms-grid-column: 5;
          -ms-grid-column-span: 1;
        }
        .transcript-container {
          -ms-grid-row: 5;
          -ms-grid-column: 3;
          -ms-grid-column-span: 5;
        }
        .collection-logo {
          -ms-grid-row: 3;
          -ms-grid-column: 1;
        }
        .waveform-scrubber-container {
          -ms-grid-row: 3;
          -ms-grid-column: 7;
          -ms-grid-column-span: 1;
        }
        .search-section {
          -ms-grid-row: 5;
          -ms-grid-column: 1;
          -ms-grid-column-span: 1;
        }
      }

      .title-date {
        grid-area: title-date;
      }

      .title {
        color: ${titleColorCss};
        font: ${titleFontCss};
      }

      .date {
        color: ${dateColorCss};
        font: ${dateFontCss};
      }

      waveform-progress {
        width: 100%;
        height: ${waveformProgressHeightCss};
      }

      playback-controls {
        grid-area: playback-controls;
      }

      .transcript-container {
        grid-area: transcript-container;
      }

      transcript-view {
        max-width: 600px;
        display: block;
      }

      .collection-logo {
        width: 100%;
        max-height: ${collectionLogoMaxHeightCss};
        object-fit: contain;
        grid-area: collection-logo;
        align-self: center;
      }

      .waveform-scrubber-container {
        width: 100%;
        height: 100%;
        grid-area: waveform-scrubber;
      }

      .search-section {
        grid-area: search-section;
      }

      .search-results-info {
        margin-top: 0.5em;
      }

      .quick-search-container {
        max-height: 150px;
        overflow-y: scroll;
        scrollbar-width: none;
        margin: 0 0.5em;
      }

      .quick-search-container::-webkit-scrollbar {
        display: none;
      }

      search-bar {
        display: block;
        margin: auto;
      }

      .no-search-results-message {
        text-align: center;
      }

      .hidden {
        display: none;
      }
    `;
  }

};

__decorate$a([property({
  type: RadioPlayerConfig
})], RadioPlayer.prototype, "config", void 0);

__decorate$a([property({
  type: TranscriptConfig
})], RadioPlayer.prototype, "transcriptConfig", void 0);

__decorate$a([property({
  type: Number
})], RadioPlayer.prototype, "currentTime", void 0);

__decorate$a([property({
  type: String
})], RadioPlayer.prototype, "searchTerm", void 0);

__decorate$a([property({
  type: Boolean
})], RadioPlayer.prototype, "skipMusicSections", void 0);

__decorate$a([property({
  type: Number
})], RadioPlayer.prototype, "percentComplete", void 0);

__decorate$a([property({
  type: Boolean
})], RadioPlayer.prototype, "isPlaying", void 0);

__decorate$a([property({
  type: Number
})], RadioPlayer.prototype, "duration", void 0);

__decorate$a([property({
  type: Number
})], RadioPlayer.prototype, "playbackRate", void 0);

__decorate$a([property({
  type: Number
})], RadioPlayer.prototype, "volume", void 0);

__decorate$a([property({
  type: Boolean
})], RadioPlayer.prototype, "shouldShowSearchResultSwitcher", void 0);

__decorate$a([property({
  type: Boolean
})], RadioPlayer.prototype, "shouldShowNoSearchResultMessage", void 0);

RadioPlayer = __decorate$a([customElement('radio-player')], RadioPlayer);
var RadioPlayer$1 = RadioPlayer;

var __decorate$b = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let AudioElement = class AudioElement extends LitElement {
  constructor() {
    super(...arguments);
    this.showControls = false;
    this.playbackRate = 1;
    this.volume = 1;
    this.sources = [];
  }

  get duration() {
    /* istanbul ignore next */
    if (!this.audioElement) {
      return 0;
    }

    return this.audioElement.duration;
  }

  get currentTime() {
    /* istanbul ignore next */
    if (!this.audioElement) {
      return 0;
    }

    return this.audioElement.currentTime;
  }

  load() {
    /* istanbul ignore else */
    if (this.audioElement) {
      this.audioElement.load(); // you have to reset the playback rate after loading

      this.audioElement.playbackRate = this.playbackRate;
    }
  }

  play() {
    /* istanbul ignore else */
    if (this.audioElement) this.audioElement.play();
  }

  pause() {
    /* istanbul ignore else */
    if (this.audioElement) this.audioElement.pause();
  }

  seekTo(seconds) {
    /* istanbul ignore if */
    if (!this.audioElement) return;
    this.audioElement.currentTime = seconds;
  }

  seekBy(seconds) {
    /* istanbul ignore if */
    if (!this.audioElement) return;
    this.audioElement.currentTime = this.audioElement.currentTime + seconds;
  }

  render() {
    return html`
      <audio
        @timeupdate=${this.handleTimeChange}
        @durationchange=${this.handleDurationChange}
        @play=${this.playbackStarted}
        @pause=${this.playbackPaused}
        @canplay=${this.canplay}
      >
        ${this.sources.map(source => html`
            <source src=${source.url} type=${source.mimetype} />
          `)}
      </audio>
    `;
  }

  get audioElement() {
    return this.shadowRoot && this.shadowRoot.querySelector('audio');
  }

  updated(changedProperties) {
    /* istanbul ignore if */
    if (!this.audioElement) return;

    if (changedProperties.has('playbackRate')) {
      this.audioElement.playbackRate = this.playbackRate;
    }

    if (changedProperties.has('volume')) {
      this.audioElement.volume = this.volume;
    }

    if (changedProperties.has('showControls')) {
      if (this.showControls) {
        this.audioElement.setAttribute('controls', 'true');
      } else {
        this.audioElement.removeAttribute('controls');
      }
    }
  }

  handleDurationChange(e) {
    const target = e.target;
    const event = new CustomEvent('durationchange', {
      detail: {
        duration: target.duration
      }
    });
    this.dispatchEvent(event);
  }

  handleTimeChange(e) {
    const target = e.target;
    const event = new CustomEvent('timeupdate', {
      detail: {
        currentTime: target.currentTime
      }
    });
    this.dispatchEvent(event);
  }

  playbackStarted() {
    const event = new Event('playbackStarted');
    this.dispatchEvent(event);
  }

  playbackPaused() {
    const event = new Event('playbackPaused');
    this.dispatchEvent(event);
  }

  canplay() {
    const event = new Event('canplay');
    this.dispatchEvent(event);
  }

};

__decorate$b([property({
  type: Boolean
})], AudioElement.prototype, "showControls", void 0);

__decorate$b([property({
  type: Number
})], AudioElement.prototype, "playbackRate", void 0);

__decorate$b([property({
  type: Number
})], AudioElement.prototype, "volume", void 0);

__decorate$b([property({
  type: Array
})], AudioElement.prototype, "sources", void 0);

AudioElement = __decorate$b([customElement('audio-element')], AudioElement);

class AudioSource {
  constructor(url, mimetype) {
    this.url = url;
    this.mimetype = mimetype;
  }

}

export { AudioSource, RadioPlayer$1 as RadioPlayer, RadioPlayerConfig, TranscriptConfig, TranscriptEntryConfig };
